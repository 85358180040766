import {useState, React } from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import FontAwesome from "react-fontawesome";
import { useKeycloak } from "@react-keycloak/web";
import Logo from "../../config/images/Neutral Markets Logo - Light.png";
import { useTranslation } from "react-i18next";
import { usePermitted } from "../../common/components/permissions/permissions";
import Modal from 'react-bootstrap/Modal';
import Badge from "react-bootstrap/Badge";

const NavHeader = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const { t } = useTranslation();
  const [showAboutModal, setShowAboutModal] = useState(false);

  const version = process.env.REACT_APP_VERSION;
  const enableTrading = process.env.REACT_APP_ENABLE_TRADING === 'true';

  const authenticated = initialized && keycloak.authenticated;
  const login = keycloak.authenticated && keycloak.tokenParsed.preferred_username;
  const showAdmin = usePermitted(["administrator","operator"]);
    const showUserAdmin = usePermitted(["administrator","operator"]);
    const showProductAdmin = usePermitted(["administrator","operator"]);
    const showMarketAdmin = usePermitted(["administrator","operator"]);
  //const showMarketData = usePermitted(["administrator","operator","market-data-provider","market-data-subscriber"]);
  const showMarketData = true; //We want these menu items to always render and show the unauthorized page if the user doesn't have access.
    const showSubmitMarks = usePermitted(["api-manage-marks"]);
    const showSubmitTrades = usePermitted(["api-manage-trades"]);
    const showReviewMarks = usePermitted(["api-approve-marks"]);
    const showReviewTrades = usePermitted(["api-approve-trades"]);
    //const showSubmitterDashboard = usePermitted(["administrator","operator","market-data-provider"]);
  const showTrading = usePermitted(["administrator"]); //temporary for now

    const handleCloseAboutModel = () => setShowAboutModal(false);
    const handleShowAboutModel = () => setShowAboutModal(true);

  return (
    <div>
      <Navbar
        expand="lg"
        bg="dark"
        data-bs-theme="dark" /*className="navbar-dark bg-dark "*/
      >
        <Container>
          <Navbar.Brand href="http://www.neutralmarkets.com/" target="_blank">
            <img src={Logo} alt="Neutral Markets" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink className="nav-item nav-link" to="/">
                {t("Home")}
              </NavLink>

              {authenticated &&
                <NavDropdown title={t('MarketData')} id="basic-nav-dropdown">
                  {
                    showMarketData &&
                    <NavDropdown.Item as={NavLink} to="/marketdata/">
                      <FontAwesome className="nav-menu-icon " name="chart-column" />{" "}
                      {t("MarketData")}
                    </NavDropdown.Item>
                  }
                  {
                    showMarketData &&
                    <NavDropdown.Item as={NavLink} to="/historicalmarketdata/">
                      <FontAwesome className="nav-menu-icon " name="chart-line" />{" "}
                      {t("HistoricalMarketData")}
                    </NavDropdown.Item>
                  }               
                  {
                    showMarketData &&
                    <NavDropdown.Item as={NavLink} to="/historicaltrades/">
                      <FontAwesome className="nav-menu-icon " name="arrow-right-arrow-left" />{" "}
                      {t("HistoricalTrades")}
                    </NavDropdown.Item>
                  }                      

                  {/* Free tools, no restrictions */}
                  <NavDropdown title={
                    <span style={{paddingLeft:"8px"}}>
                      <FontAwesome className="nav-menu-icon" name="gauge" />
                      {" "}
                      <span >{t('Dashboards')}</span>
                    </span>
                  } id="dashboards-dropdown" className="dropend">
                    <NavDropdown.Item as={NavLink} to="/dashboards/albertaenvironmental">
                      <FontAwesome className="nav-menu-icon " name="leaf" />{" "}
                      {t("AlbertaEnvironmental")}
                    </NavDropdown.Item>           
                    <NavDropdown.Item as={NavLink} to="/dashboards/albertaelectricity">
                      <FontAwesome className="nav-menu-icon " name="bolt" />{" "}
                      {t("AlbertaElectricity")}
                    </NavDropdown.Item>
                  </NavDropdown>    

                  {
                    showMarketData &&
                    <NavDropdown title={
                      <span style={{paddingLeft:"8px"}}>
                        <FontAwesome className="nav-menu-icon" name="tools" />
                        {" "}
                    <span >{t('Tools')}</span>
                      </span>
                    } id="tools-dropdown" className="dropend">
                      <NavDropdown.Item as={NavLink} to="/dashboards/albertaenvironmental/trades">
                        <FontAwesome className="nav-menu-icon " name="leaf" />{" "}
                        {t("AlbertaEnvironmentalTrades")}
                      </NavDropdown.Item>      
                      <NavDropdown.Item as={NavLink} to="/dashboards/albertaenvironmental/registry">
                        <FontAwesome className="nav-menu-icon " name="book" />{" "}
                        {t("AlbertaEnvironmentalRegistry")}
                      </NavDropdown.Item>  
                      <NavDropdown.Item as={NavLink} to="/dashboards/albertaenvironmental/issues">
                        <FontAwesome className="nav-menu-icon " name="seedling" />{" "}
                        {t("AlbertaEnvironmentalIssues")}
                      </NavDropdown.Item>    
                      <NavDropdown.Item as={NavLink} to="/dashboards/albertaenvironmental/statuschanges">
                        <FontAwesome className="nav-menu-icon " name="arrows-turn-to-dots" />{" "}
                        {t("AlbertaEnvironmentalStatusChanges")}
                      </NavDropdown.Item>                                                                  
                    </NavDropdown>   
                  } 
        
                  {(showSubmitMarks || showSubmitTrades) && <NavDropdown.Divider />}
                  {showSubmitMarks && 
                    <NavDropdown.Item as={NavLink} to="/marketdata/submitmarks">
                      <FontAwesome
                        className="nav-menu-icon"
                        name="comment-dollar"
                      />{" "}
                      {t("MarketDataSubmitMarks")}
                    </NavDropdown.Item>
                  }
                  {showSubmitTrades && 
                    <NavDropdown.Item as={NavLink} to="/marketdata/submittrades">
                      <FontAwesome className="nav-menu-icon" name="comments-dollar" />{" "}
                      {t("MarketDataSubmitTrades")}
                    </NavDropdown.Item>
                  }
                  {(showReviewMarks || showReviewTrades) && 
                    <NavDropdown.Divider />
                  }
                  {showReviewMarks && 
                    <NavDropdown.Item as={NavLink} to="/marketdata/reviewmarks">
                      <FontAwesome className="nav-menu-icon" name="magnifying-glass-dollar" />{" "}
                      {t("MarketDataReviewMarks")}   
                      {" "} 
                      {           
                        props.unapprovedMarkCount > 0 &&
                        <Badge pill bg="warning" text="dark">
                          {props.unapprovedMarkCount}
                        </Badge>
                      }
                    </NavDropdown.Item>
                  }
                  {showReviewTrades && 
                    <NavDropdown.Item as={NavLink} to="/marketdata/reviewtrades">
                      <FontAwesome className="nav-menu-icon" name="magnifying-glass-arrow-right" />{" "}
                      {t("MarketDataReviewTrades")}
                      {" "} 
                      {           
                        props.unapprovedTradeCount > 0 &&
                        <Badge pill bg="warning" text="dark">
                          {props.unapprovedTradeCount}
                        </Badge>
                      }                      
                    </NavDropdown.Item>
                  }
                  {/*{(showSubmitterDashboard) && 
                    <NavDropdown.Divider />
                  }
                  {showSubmitterDashboard && 
                    <NavDropdown.Item as={NavLink} to="/marketdata/submitterdashboard">
                      <FontAwesome className="nav-menu-icon" name="chart-pie" />{" "}
                      {t("MarketDataSubmitterDashboard")}
                    </NavDropdown.Item>
                  } */}
                </NavDropdown>
              }
              { enableTrading && showTrading &&
                <NavLink className="nav-item nav-link" to="/trading">
                  {t("Trading")}
                </NavLink>        
              }      
            </Nav>
            <Nav>
            {authenticated && showAdmin &&
                <NavDropdown title={t('Admin')} id="basic-nav-dropdown" align="end">
                  {
                    showUserAdmin &&
                    <NavDropdown.Item as={NavLink} to="/admin/auth">
                      <FontAwesome className="nav-menu-icon" name="users" />{" "}
                      {t("UserAdmin")}
                    </NavDropdown.Item>
                  }
                  {showProductAdmin && 
                    <NavDropdown.Item as={NavLink} to="/admin/products">
                      <FontAwesome className="nav-menu-icon" name="coins" />{" "}
                      {t("ProductAdmin")}
                    </NavDropdown.Item>
                  }
                  {showMarketAdmin && 
                    <NavDropdown.Item as={NavLink} to="/admin/markets">
                      <FontAwesome className="nav-menu-icon" name="store" />{" "}
                      {t("MarketAdmin")}
                    </NavDropdown.Item>
                  }
                </NavDropdown>
              }                 
              {authenticated && 
                <NavDropdown title= {login} id="basic-nav-dropdown" align="end">
                  <NavDropdown.Item as={NavLink} to="/profile">
                    <FontAwesome className="nav-menu-icon" name="user" />{" "}
                    {t("UserProfile")}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleShowAboutModel}>
                    <FontAwesome className="nav-menu-icon" name="info-circle" />{" "}
                    {t("About")}
                  </NavDropdown.Item>                
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => keycloak.logout()}>
                    <FontAwesome className="nav-menu-icon" name="sign-out" />{" "}
                    {t("Logout")}
                  </NavDropdown.Item>
                </NavDropdown>
              }            
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={showAboutModal} size="md" onHide={handleCloseAboutModel}
               backdrop="static"
              keyboard={false}>
          <Modal.Header className="bg-dark text-light" data-bs-theme="dark" closeButton>
            <Modal.Title>© Neutral Markets 2024</Modal.Title>
          </Modal.Header>                
           <Modal.Body className="bg-dark text-light" >
           {t("Version")}{": "}{version}
          </Modal.Body>
      </Modal>      
    </div>
  );
};

export default NavHeader;

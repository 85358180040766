import React, { Component } from "react";
import Loading from "../../common/components/loading/loading";
import { getProductWithQualityAttributes } from "../../common/services/products/productService";
import { getMarketPricesByMarket } from "../../common/services/marketdata/marketPriceUtilities";
import {
  getAggregateMarksByMarket,
  getAggregateForwardMarksByMarket,
} from "../../common/services/marketdata/markService";
import MarksForwardTable from "./marksForwardTable";
import MarksHistoryTable from "./marksHistoryTable";
import MarketPriceTile from "./marketPriceTile";
import AttributeSpreads from "./attributeSpreads";
import TradesTable from "./tradesTable";
import { withTranslation } from "react-i18next";
import ListGroup from "react-bootstrap/ListGroup";
import Input from "../../common/components/form/input";
import { getAggregateTradesByMarket } from "../../common/services/marketdata/tradeService";
import Modal from "react-bootstrap/Modal";
import { formatDate, priorBusinessDay } from "../../common/services/utilities";
import { memoize } from "lodash";

class MarketDataDashboard extends Component {
  state = {
    marketAttributes: [],
    marks: [],
    forwardMarks: [],
    trades: [],
    marketPrices: [],
    forwardReportingPeriodicity: "",
    historicalReportingPeriodicity: "",    
    isLoading: true,
    historicalDataIsLoading: true,
    forwardDataIsLoading: true,
    tradeDataIsLoading: true,
    marketPriceIsLoading: true,
    marketDate: null,
    fromDate: null,
    toDate: null,
    termScheduleId: null,
    priceBasisId: null,
    currencyId: null,
    uomId: null,
    pricePrecision: 2,
    volumePrecision: 0,
    currencySymbol: "$",
    showAttributeSpreadModal: false,
  };

  async componentDidMount() {
    const { productId, market } = this.props;

    const marketDate = new Date().toLocaleDateString("fr-CA");

    // const [venue, product, termSchedule] = await Promise.all([
    //   getVenue(market.venueId),
    //   getProductWithQualityAttributes(productId),
    //   getTermSchedule(market.termScheduleId),
    // ]);

    const {venue, termSchedule} =this.props;
    const {forwardReportingPeriodicity, historicalReportingPeriodicity} = termSchedule;
    const [product] = await Promise.all([
      getProductWithQualityAttributes(productId),
    ]);

    const timeZone = venue && this.getTimeZone(venue.timeZone);

    this.setState({
      marketDate,
      product,
      marketAttributes: market.marketAttributes,
      termScheduleId: market.termScheduleId,
      forwardReportingPeriodicity: forwardReportingPeriodicity,
      historicalReportingPeriodicity: historicalReportingPeriodicity,
      priceBasisId: market.marketAttributes[0].priceBasisId,
      currencyId: market.marketAttributes[0].currencyId,
      uomId: market.marketAttributes[0].uomId,
      pricePrecision: market.marketAttributes[0].pricePrecision ?? 2,
      volumePrecision: market.marketAttributes[0].volumePrecision ?? 0,
      currencySymbol: market.marketAttributes[0].currencySymbol ?? "$",
      timeZone,
      isLoading: false,
    });

    if (marketDate && market && termSchedule) {
      await this.loadData(
        marketDate,
        termSchedule.historicalReportingPeriodicity
      );
    }
  }

  getTimeZone = memoize((timeZone) => {
    return `${timeZone} (${
      Intl.DateTimeFormat("us", {
        timeZoneName: "short",
        timeZone: timeZone,
      })
        .formatToParts()
        .find((i) => i.type === "timeZoneName").value
    })`;
  });

  loadData = async (marketDate, historicalReportingPeriodicity) => {
    const lookbackWindow = historicalReportingPeriodicity === "Hour" ? 1 : 7;
    const tradeLookbackWindow = 14;
    const fromDate = this.calculateFromDate(marketDate, lookbackWindow);
    const curveFromDate = this.calculateCurveFromDate(marketDate);
    const tradeFromDate = this.calculateFromDate(marketDate, tradeLookbackWindow);
    const toDate = this.calculateToDate(marketDate);

    this.setState({ fromDate, toDate });

    const { market } = this.props;
    const { marketId } = market;

    await Promise.all([
      this.loadTrades(marketId, tradeFromDate, toDate),
      this.loadMarks(marketId, fromDate, toDate, curveFromDate),
      this.loadMarketPrices(marketId, toDate),
    ]);
  };

  calculateFromDate = (marketDate, lookbackWindow) => {
    let fromDate = new Date(marketDate);
    fromDate.setMinutes(fromDate.getMinutes() + fromDate.getTimezoneOffset());
    fromDate.setDate(fromDate.getDate() - lookbackWindow);
    return fromDate;
  };

  calculateCurveFromDate = (marketDate) => {
    const curveFromDate = new Date(marketDate);
    curveFromDate.setMinutes(
      curveFromDate.getMinutes() + curveFromDate.getTimezoneOffset()
    );
    curveFromDate.setDate(curveFromDate.getDate() - 1);
    return priorBusinessDay(curveFromDate);
  };

  calculateToDate = (marketDate) => {
    const toDate = new Date(marketDate);
    toDate.setMinutes(toDate.getMinutes() + toDate.getTimezoneOffset());
    return toDate;
  };

  loadTrades = async (marketId, fromDate, toDate) => {
    const trades = await getAggregateTradesByMarket(
      marketId,
      formatDate(fromDate),
      formatDate(toDate)
    );

    this.setState({ trades, tradeDataIsLoading: false });
  };

  loadMarks = async (marketId, fromDate, toDate, curveFromDate) => {
    const [marks, forwardMarks] = await Promise.all([
      getAggregateMarksByMarket(
        marketId,
        formatDate(fromDate),
        formatDate(toDate)
      ),
      getAggregateForwardMarksByMarket(
        marketId,
        formatDate(curveFromDate),
        formatDate(toDate)
      ),
    ]);

    this.setState({
      marks: marks.filter((x) => x.markState === "Approved"),
      forwardMarks: forwardMarks.filter((x) => x.markState === "Approved"),
      historicalDataIsLoading: false,
      forwardDataIsLoading: false,
    });
  };

  loadMarketPrices = async (marketId, marketDate) => {
    const marketPrices = await getMarketPricesByMarket(
      marketId,
      marketDate.toLocaleDateString("fr-CA")
    );

    this.setState({ marketPrices, marketPriceIsLoading: false });
  };

  handleMarketDateChange = (marketDate) => {
    this.setState({ marketDate });
    this.loadData(marketDate, this.state.historicalReportingPeriodicity);
  };

  handlemarketAttributeSelect = (marketAttribute) => {
    this.setState({
      priceBasisId: marketAttribute.priceBasisId,
      currencyId: marketAttribute.currencyId,
      uomId: marketAttribute.uomId,
    });
  };

  handleAttributeSpreadClose = () => {
    this.setState({ showAttributeSpreadModal: false });
  };

  getFilteredData = memoize((data, priceBasisId, uomId, currencyId) => {
    return data.filter(
      (x) =>
        x.priceBasisId === priceBasisId &&
        x.uomId === uomId &&
        x.currencyId === currencyId
    );
  });

  getFilteredMarks = () => ({
    marks: this.getFilteredData(
      this.state.marks,
      this.state.priceBasisId,
      this.state.uomId,
      this.state.currencyId
    ),
  });

  getFilteredForwardMarks = () => ({
    forwardMarks: this.getFilteredData(
      this.state.forwardMarks,
      this.state.priceBasisId,
      this.state.uomId,
      this.state.currencyId
    ),
  });

  getFilteredTrades = () => ({
    trades: this.getFilteredData(
      this.state.trades,
      this.state.priceBasisId,
      this.state.uomId,
      this.state.currencyId
    ),
  });

  getFilteredMarketPrices = () => ({
    marketPrices: this.getFilteredData(
      this.state.marketPrices,
      this.state.priceBasisId,
      this.state.uomId,
      this.state.currencyId
    ),
  });

  render() {
    const {
      product,
      timeZone,
      marketAttributes,
      termScheduleId,
      historicalReportingPeriodicity,
      forwardReportingPeriodicity,
      isLoading,
      marketPriceIsLoading,
      historicalDataIsLoading,
      forwardDataIsLoading,
      tradeDataIsLoading,
      toDate,
      fromDate,
      marketDate,
      priceBasisId,
      currencyId,
      uomId,
      pricePrecision,
      volumePrecision,
      currencySymbol,
      showAttributeSpreadModal,
    } = this.state;

    const { forwardMarks } = this.getFilteredForwardMarks();
    const { marks } = this.getFilteredMarks();
    const { trades } = this.getFilteredTrades();
    const { marketPrices } = this.getFilteredMarketPrices();

    const currentMarketPrice = marketPrices.filter(
      (x) => x.qualityAttributeValues === null && x.termName === "Latest"
    )[0];
    const mtdMarketPrice = marketPrices.filter(
      (x) => x.qualityAttributeValues === null && x.termName === "MTD"
    )[0];

    if (isLoading) return <Loading />;

    const { t, market } = this.props;
    const { marketId } = market;

    var date = new Date();
    const endDate = date.toLocaleDateString("fr-CA");

    return (
      <div className="container-fluid">
        <Modal
          size="lg"
          show={showAttributeSpreadModal}
          onHide={this.handleAttributeSpreadClose}
          //centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            className="bg-dark text-light"
            data-bs-theme="dark"
            closeButton
          >
            <Modal.Title>{t("QualityAttributeDifferentials")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-dark text-light">
            <AttributeSpreads
              marks={marks}
              pricePrecision={pricePrecision}
              currencySymbol={currencySymbol}
              product={product}
              termScheduleId={termScheduleId}
              fromDate={fromDate}
              toDate={toDate}
            />
          </Modal.Body>
        </Modal>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <ListGroup
              value={marketAttributes[0]}
              horizontal="sm"
              data-bs-theme="dark"
            >
              {marketAttributes.map((attribute, index) => (
                <ListGroup.Item
                  className="market-attribute-selector"
                  key={index}
                  action
                  active={priceBasisId === attribute.priceBasisId}
                  onClick={() => this.handlemarketAttributeSelect(attribute)}
                >
                  {attribute.priceBasisName +
                    " (" +
                    attribute.currencyName +
                    "/" +
                    attribute.uomName +
                    ")"}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <Input
              data-bs-theme="dark"
              type="date"
              max={endDate}
              onChange={(e) =>
                this.handleMarketDateChange(e.currentTarget.value)
              }
              //name = "name"
              value={marketDate}
              label={t("MarketDate")}
            />
          </div>
          <div className="col-md-3"></div>
          <div
            className="col-md-2 flex-column d-flex"
            style={{ textAlign: "center" }}
          >
            {product.qualityAttributes.filter((x) => x.isPriceDriver).length >
              0 && (
              <button
                onClick={() =>
                  this.setState({ showAttributeSpreadModal: true })
                }
                className="btn btn-sm btn-secondary"
                style={{ marginTop: "auto" }}
              >
                {t("QualityAttributeDifferentials")}
              </button>
            )}
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-2 mt-auto">
            {t("TimeZone")}:<br />
            {timeZone}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 market-data-tile">
            {marketPriceIsLoading ? (
              <Loading />
            ) : (
              currentMarketPrice && (
                <MarketPriceTile
                  marketPrice={currentMarketPrice}
                  pricePrecision={pricePrecision}
                  currencySymbol={currencySymbol}
                />
              )
            )}
          </div>
          <div className="col-md-6 market-data-tile">
            {marketPriceIsLoading ? (
              <Loading />
            ) : (
              mtdMarketPrice && (
                <MarketPriceTile
                  marketPrice={mtdMarketPrice}
                  pricePrecision={pricePrecision}
                  currencySymbol={currencySymbol}
                />
              )
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 market-data-tile">
            <h5>{t("SpotHistory")}</h5>
            {historicalDataIsLoading ? (
              <Loading />
            ) : (
              <MarksHistoryTable
                marks={marks}
                product={product}
                marketId={marketId}
                termScheduleId={termScheduleId}
                historicalReportingPeriodicity={historicalReportingPeriodicity}
                fromDate={fromDate}
                toDate={toDate}
                priceBasisId={priceBasisId}
                currencyId={currencyId}
                uomId={uomId}
                pricePrecision={pricePrecision}
                volumePrecision={volumePrecision}
                currencySymbol={currencySymbol}
              />
            )}
          </div>
          <div className="col-md-6 market-data-tile">
            <h5>{t("ForwardCurve")}</h5>
            {forwardDataIsLoading ? (
              <Loading />
            ) : (
              <MarksForwardTable
                marks={forwardMarks}
                product={product}
                marketId={marketId}
                termScheduleId={termScheduleId}
                forwardReportingPeriodicity={forwardReportingPeriodicity}
                marketDate={marketDate}
                priceBasisId={priceBasisId}
                currencyId={currencyId}
                uomId={uomId}
                pricePrecision={pricePrecision}
                volumePrecision={volumePrecision}
                currencySymbol={currencySymbol}
              />
            )}
          </div>
        </div>
        {trades.length > 0 && (
          <div className="row">
            <div className="col market-data-tile">
              <h5>{t("RecentTrades")}</h5>
              {tradeDataIsLoading ? (
                <Loading />
              ) : (
                <TradesTable
                  trades={trades}
                  product={product}
                  marketId={marketId}
                  termScheduleId={termScheduleId}
                  fromDate={fromDate}
                  tradeDate={marketDate}
                  priceBasisId={priceBasisId}
                  currencyId={currencyId}
                  uomId={uomId}
                  pricePrecision={pricePrecision}
                  volumePrecision={volumePrecision}
                  currencySymbol={currencySymbol}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(MarketDataDashboard);
